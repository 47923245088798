import "../../../assets/css/reportResults/wantsView/details-heading.css";

import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";

import CompareBox from "./compare-box.jsx";
import DetailsHeading from "./details-heading.jsx";

import TotalPayroll from "../../detailsViews/1/1-1.jsx";
import InboundOutBound from "../../detailsViews/1/1-2.jsx";
import ElectricityCost from "../../detailsViews/1/1-3.jsx";
import WaterCost from "../../detailsViews/1/1-4.jsx";
import GasCost from "../../detailsViews/1/1-5.jsx";
import LandCost from "../../detailsViews/1/1-6.jsx";
import TurnoverRate from "../../detailsViews/2/2-1.jsx";
import ManufacturingDensity from "../../detailsViews/2/2-2.jsx";
import ManufacturingGrowth from "../../detailsViews/2/2-3.jsx";
import MachineryEquipment from "../../detailsViews/2/2-4.jsx";
import EngineeringGraduates from "../../detailsViews/2/2-5.jsx";
import TsuGraduates from "../../detailsViews/2/2-6.jsx";
import AbsenteeismRate from "../../detailsViews/3/3-1.jsx";
import StrategicDriver from "../../detailsViews/3/3-2.jsx";
import StrikesHistory from "../../detailsViews/3/3-3.jsx";
import LaborLawsuits from "../../detailsViews/3/3-4.jsx";
import OutboundTrucks from "../../detailsViews/4/4-1.jsx";
import Airports from "../../detailsViews/5/5-1.jsx";
import NumberBuildings from "../../detailsViews/5/5-2.jsx";
import StatuoryIncentives from "../../detailsViews/6/6-1.jsx";
import StateElections from "../../detailsViews/6/6-2.jsx";
import Homicides from "../../detailsViews/7/7-1.jsx";
import CarTheft from "../../detailsViews/7/7-2.jsx";
import HomeTheft from "../../detailsViews/7/7-3.jsx";
import OrganizedCrime from "../../detailsViews/7/7-4.jsx";
import InsecurityPerception from "../../detailsViews/7/7-5.jsx";
import CorruptionIndex from "../../detailsViews/7/7-6.jsx";
import SchoolsHospitals from "../../detailsViews/8/8-1-3.jsx";
import HousingCost from "../../detailsViews/8/8-4.jsx";
import NationalProgress from "../../detailsViews/8/8-7.jsx";


const Details = ({
  wantsMatrixRef,
  setIsDetailsActive,
  isDetailsActive,
  details,
  city_names,
  setShowExtraDetails,
  tableComponentLoaded,
  setTableComponentLoaded,
  musts,
  assumptions,
  variableCosts,
  allVariableCosts,
  reportID,
  wantsDetails,
  totalPayroll
}) => {
  //Initialize a map of components to render based on the variable name
  const componentsMap = {
    "65cd417ff955fa725381e7e7": TotalPayroll,
    "65cd419ff955fa725381e7e8": InboundOutBound, //logistics costs
    "65cd41a8f955fa725381e7e9": ElectricityCost,
    "65cd41b0f955fa725381e7ea": WaterCost,
    "65cd41b9f955fa725381e7eb": GasCost,
    "65cd41c4f955fa725381e7ec": LandCost,
    "65cd4263f955fa725381e7f2": TurnoverRate,
    "65cd425cf955fa725381e7f1": ManufacturingDensity,
    "65cd4254f955fa725381e7f0": ManufacturingGrowth,
    "65cd424af955fa725381e7ef": MachineryEquipment, //skilled labor
    "65cd4242f955fa725381e7ee": EngineeringGraduates,
    "65cd4238f955fa725381e7ed": TsuGraduates,
    "65cd42a0f955fa725381e7f6": AbsenteeismRate,
    "65cd429af955fa725381e7f5": StrategicDriver, //union environment
    "65cd4294f955fa725381e7f4": LaborLawsuits,
    "65cd428ef955fa725381e7f3": StrikesHistory,
    "65cd42c0f955fa725381e7f9": OutboundTrucks, //distance to client
    "65cd42baf955fa725381e7f8": OutboundTrucks, //distance to seaport
    "65cd42b5f955fa725381e7f7": OutboundTrucks, //distance to supplier
    "65cd42daf955fa725381e7fc": Airports, //connectivity travel
    "65cd42d4f955fa725381e7fb": NumberBuildings, //availability of potential land
    "65cd42f3f955fa725381e7fe": StatuoryIncentives, //government incentives
    "65cd42ecf955fa725381e7fd": StateElections, //government landscape
    "65cd4321f955fa725381e804": Homicides,
    "65cd431af955fa725381e803": CarTheft,
    "65cd4314f955fa725381e802": HomeTheft,
    "65cd430ef955fa725381e801": OrganizedCrime,
    "65cd4307f955fa725381e800": InsecurityPerception,
    "65cd4302f955fa725381e7ff": CorruptionIndex,
    "65cd436af955fa725381e80b": SchoolsHospitals, //private schools
    "65cd4364f955fa725381e80a": SchoolsHospitals, //private hospitals
    "65cd435df955fa725381e809": SchoolsHospitals, //recognized retail chains in the city
    "65cd4355f955fa725381e808": HousingCost, // cost of living
    "65cd4340f955fa725381e805": NationalProgress, //progress index
  };
  //Define all cities as selected by default
  const initial_filtered = city_names.map(city => city_names.indexOf(city));
  const [filtered, setFiltered] = useState(initial_filtered);
  //Placeholder for selected component
  const [variableId, setVariableId] = useState("");
  const SelectedComponent = variableId && componentsMap[variableId];

  useEffect(() => {
    //If there are no details, set the table component to not loaded
    if (!details) {
      setTableComponentLoaded(false);
    } else {
      //Otherwise, set the table component to loaded and set the variable name
      setTableComponentLoaded(true);
      setVariableId(details.variableIdRef.toString());
    }
  }, [isDetailsActive]);

  return (
    <section className={`details-wrapper ${isDetailsActive ? "active" : ""}`}>
      {tableComponentLoaded ? (
        <Container>
          <DetailsHeading
            wantsMatrixRef={wantsMatrixRef}
            setIsDetailsActive={setIsDetailsActive}
            details={details}
            city_names={city_names}
            setShowExtraDetails={setShowExtraDetails}
            wantsDetails={wantsDetails}
            land = {assumptions.land}
          />
          <Row className="tabs-container">
            <CompareBox
              origin={`details-${details.variableIdRef}`}
              cityNames={city_names}
              comp_variant={"table"}
              setFiltered={setFiltered}
            />
            {SelectedComponent && (
              <SelectedComponent
                details={details}
                city_names={city_names}
                filtered={filtered}
                reportID={reportID}
                musts={musts}
                assumptions={assumptions}
                variableCosts={variableCosts}
                allVariableCosts={allVariableCosts}
                totalPayroll={totalPayroll}
                wantsMatrixRef={wantsMatrixRef}
              />
            )}
          </Row>
        </Container>
      ) : (
        <>
          <div>Loading table...</div>
        </>
      )}
    </section>
  );
};

export default Details;