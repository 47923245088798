import "../../../assets/css/siteSelection/logisticsCost.css"

import axios from "axios"
import FileSaver from "file-saver"
import { format } from "date-fns"
import Swal from "sweetalert2"
import { useEffect, useState, useRef } from "react"
import { Col, Container, Row } from "react-bootstrap"

import goBack from "../../../assets/images/arrowDown.svg"
import {formatCommas } from "../../../utils/formatCommas"
import loadingGif from "../../../assets/images/loading.gif"
import checkGreen from "../../../assets/images/check_green.svg"
import { customAlert } from "../../../utils/customAlert"

export default function LogisticCosts(props) {
	const [citySearch, setCitySearch] = useState("")
	const [showPlacesContainer, setShowPlacesContainer] = useState("")
	const [placesFound, setPlacesFound] = useState([])
	const [downloading, setDownloading] = useState(false)
	const [isDownloaded, setIsDownloaded] = useState(false)

	const logisticsCostsFile = useRef();

	useEffect(() => {
		if (props.showLogisticCosts) {
			const currentInboundGroups = props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups
			const destinations = props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations
				.filter(origin => origin.fullName !== "")
				.map(destination => destination.fullName);
			const variableCitiesCopy = props.variableCost.cities
			let updatedCities



			currentInboundGroups.forEach(group => {
				const currentGroupsOrigins = group.origins.filter(origin => origin.fullName !== "").map(origin => origin.fullName);
				updatedCities = variableCitiesCopy.map(city => {
					city.inboundGroups.map(inboundGroup => {
						currentGroupsOrigins.forEach(currentOrigin => {
							const exists = inboundGroup.origins.some(origin => origin.origin === currentOrigin);
							if (!exists && group.groupName === inboundGroup.groupName) {
								inboundGroup.origins.push({
									origin: currentOrigin,
									price: ""
								});
							}
						});
					});
					return city;
				});
				updatedCities = variableCitiesCopy.map(city => {
					const exists = city.inboundGroups.some(inboundGroup => inboundGroup.groupName === group.groupName);
					if (!exists) {
						// Add the new inboundGroup to the city
						if (group.origins.length > 1 || (group.origins.length === 1 && group.origins[0].fullName !== "")) {
							city.inboundGroups.push({
								groupName: group.groupName,
								origins: group.origins.filter(origin => origin.fullName !== "").map(origin => {
									return {
										origin: origin.fullName,
										price: ""
									}
								})
							});
						}
					}
					return city
				})
			});
			// console.log(updatedCities)
			props.setVariableCost(prevState => {
				return {
					...prevState,
					cities: updatedCities
				}
			})

			destinations.forEach(destination => {
				props.setVariableCost(prevState => {
					const updatedCities = prevState.cities.map(city => {
						const exists = city.outbound.some(outbound => outbound.destination === destination);
						if (!exists) {
							return {
								...city,
								outbound: [
									...city.outbound,
									{
										destination: destination,
										price: "",
									}
								]
							};
						}
						return city;
					});
					return {
						...prevState,
						cities: updatedCities
					};
				});
			});
		}
	}, [props.showLogisticCosts]);

	useEffect(() => {
		fetchCoordenates();
	}, [citySearch])

	useEffect(() => {
		if(props.savedDraft) {
			setIsDownloaded(false)
		}
	}, [props.savedDraft])

	const fetchCoordenates = async () => {
		const { data } = await axios.get(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${citySearch}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
		);
		setPlacesFound(data.features)
	};

	const uploadLogisticsCostsFile = async(e) => {
		const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		const selectedFile = e.target.files[0];

		if (selectedFile && allowTypes !== selectedFile.type) {
			await Swal.fire({
				icon: "error",
                title: "File not supported",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
				timerProgressBar: true,
			})
			return
		}

		// return if no file selected
		if (!selectedFile) return

		// add neccessary data to the form data to process the file
		const data = new FormData();
		data.append("reportId", props.draft._id);
		data.append("musts", JSON.stringify(props.draft.siteSelectionReport.must.cities))
		data.append("logisticsInbound", JSON.stringify(props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups))
		data.append("logisticsOutbound", JSON.stringify(props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations))
		data.append("logisticsCosts", selectedFile);
		axios.post(process.env.REACT_APP_UPLOAD_LOGISTICS, data).then(resFile => {
			if (resFile.data.status) {
				const pricesResponse = resFile.data.data.pricesResponse
				const variableCostCities = JSON.parse(JSON.stringify(props.variableCost.cities))

				variableCostCities.forEach(city => {
					const cityFile = pricesResponse.find(cityFile => cityFile.cityName === city.cityName)
					city.inboundGroups = cityFile ? cityFile.inboundGroups : city.inboundGroups
					city.outbound = cityFile ? cityFile.outbound.map(outbound => {
						const matchingOutbound = city.outbound.find(o => o.destination === outbound.destination);
						return {
							...outbound,
							annualCost: matchingOutbound ? matchingOutbound.annualCost : 0
						};
					}) : city.outbound
				})

				props.setVariableCost({
					...props.variableCost,
					cities: variableCostCities
				})
			} else {
				Swal.fire({
					icon: "error",
					title: resFile.data.message,
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
				})
			}
		}).catch(err => {
			console.log(err)
			customAlert(err.reponse?.data.message || err.response?.statusText)
		})
	}

	const getSpreadsheet = () => {
		setDownloading(true)
		let requestData = {
			reportId: props.draft._id,
			logistics: props.draft.siteSelectionReport.assumption.logistics,
			variableCost: props.variableCost.cities,
			cityNames: props.draft.siteSelectionReport.must.cities.map(city => city.municipality_name)
		};
		axios.post(process.env.REACT_APP_GET_LOGISTICS,requestData, { responseType: 'arraybuffer' })
		.then((response) => {
			if(response.data){
				const blob = new Blob([response.data],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				FileSaver.saveAs(blob, `Logistics_${props.draft.reportName}_${format(new Date(), "dd-MM-yyyy")}.xlsx`);
				setDownloading(false)
				setIsDownloaded(true)
			}
		}).catch((error) => {
			setDownloading(false)
			console.log(error)
			customAlert(error.response?.data?.message || error.response?.statusText)
		})
	}

	return (
		<section className={`logisticCosts ${props.showLogisticCosts ? "show" : ""}`}>
			<Container>
				<div className="titleAndSave">
					<img src={goBack} alt="Prodensa Automation" onClick={() => props.setShowLogisticCosts(false)} />
					<h4>Logistics costs</h4>
					<button onClick={() => props.saveDraft()}><span className="d-none d-lg-block">Save draft</span></button>
					<div className="savingDraftContainer my-auto ms-2 d-none d-lg-block">
						{props.savedDraft &&
							<img className="loading show"
								src={checkGreen}
								alt="ProdensaAutomation" />
							|| !props.savedDraft &&
							<img className={`loading ${props.savingDraft ? "show" : ""}`}
								src={loadingGif}
								alt="ProdensaAutomation" />
						}
					</div>
					<button className="d-none d-lg-flex" id="download" onClick={() => getSpreadsheet()}><span className="d-none d-lg-block">Download(.xlsx)</span></button>
					<div className="savingDraftContainer my-auto ms-2 d-none d-lg-block">
						{isDownloaded &&
							<img className="loading show"
							src={checkGreen}
							alt="ProdensaAutomation" />
							|| !isDownloaded &&
							<img className={`loading ${downloading ? "show" : ""}`}
							src={loadingGif}
							alt="ProdensaAutomation" />
						}
					</div>
					<input type="file" id="upload" accept=".xlsx" ref={logisticsCostsFile} style={{display: "none"}} onChange={(e) => uploadLogisticsCostsFile(e)} />
					<button className="d-none d-lg-flex" style={{marginLeft: "20px"}} onClick={() => logisticsCostsFile.current && logisticsCostsFile.current.click()}><span className="d-none d-lg-block">Upload(.xlsx)</span></button>
				</div>
				<h5 className="variableSubTitle">Operational costs</h5>
				<div className="headerTitle">
					<h5>
						Inbound
					</h5>
					<div className="currencyButtonContainer">
						<button className={`currency ${props.variableCost?.currency?.logisticsInbound === "USD" ? "selected" : ""}`} 
							onClick={() => {
								props.setVariableCost(prevState => {
                                            return {
                                                ...prevState,
                                                currency: {
                                                    ...prevState.currency,
                                                    logisticsInbound: "USD"
                                                }
                                            }
                                        })
							}}
						>
							USD
						</button>
						
						<button className={`currency ${props.variableCost?.currency?.logisticsInbound === "MXN" ? "selected" : ""}`} 
							onClick={() => {
								props.setVariableCost(prevState => {
                                            return {
                                                ...prevState,
                                                currency: {
                                                    ...prevState.currency,
                                                    logisticsInbound: "MXN"
                                                }
                                            }
                                        })
							}}
						>
							MXN
						</button>
					</div>
				</div>
				{props.draft && props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map((inboundGroup, indexInboundGroup) => (
					<div className="group mb-4" key={indexInboundGroup}>
						<div className="headerTitle">
							<h5>
								{inboundGroup.groupName}
							</h5>
							<span>Cost per unit</span>
						</div>
						<Row className="rowInformation">
							<Col lg={4} className="px-0">
								<div className="informationHeader">
									<h5 className="port">Port/Airport</h5>
									<h5 className="origin">Origin</h5>
								</div>
								{props.draft && props.showLogisticCosts && inboundGroup.origins.map((origins, indexOrigins) => (
									<div key={indexOrigins} className="informationValue">
										<input className="firstElement" type="text" placeholder="Add port/airport (optional)" value={origins.portAirport}
											onChange={(e) => {
												props.setDraft(prevState => {
													return {
														...prevState,
														siteSelectionReport: {
															...prevState.siteSelectionReport,
															assumption: {
																...prevState.siteSelectionReport.assumption,
																logistics: {
																	...prevState.siteSelectionReport.assumption.logistics,
																	logisticsInbound: {
																		...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
																		inboundGroups: prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map((group, indexGroup) => {
																			if (indexGroup === indexInboundGroup) {
																				return {
																					...group,
																					origins: group.origins.map((origin, index) => {
																						if (index === indexOrigins) {
																							return {
																								...origin,
																								portAirport: e.target.value
																							}
																						} else {
																							return origin
																						}
																					})
																				}
																			} else {
																				return group
																			}
																		})
																	}
																}
															}
														}
													}
												})
											}}
										/>
										<input className="secondElement" type="text" value={origins.city} disabled />
									</div>
								))}

							</Col>

							<Col lg={8} className="citiesScroll">
								<div className="informationHeaderCities">
									{props.variableCost && props.variableCost.cities.filter(city => props.draft && props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => (
										<h5 key={index} className="cityName">{city.cityName}</h5>
									))}
								</div>
								{props.draft && props.showLogisticCosts && inboundGroup.origins.map((inboundInfo, indexInbound) => (
									inboundInfo.fullName !== "" && <div key={indexInbound} className="informationValueCities" name={inboundInfo.Fullname}>
										{props.variableCost && props.variableCost.cities.filter(city => props.draft && props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, indexCity) => {
											let priceToShow = city.inboundGroups.filter(group => group.groupName === inboundGroup.groupName)[0]?.origins.filter(origin => origin.origin === inboundInfo.fullName)[0]?.price === "" ?
											"" : formatCommas(city.inboundGroups.filter(group => group.groupName === inboundGroup.groupName)[0]?.origins.filter(origin => origin.origin === inboundInfo.fullName)[0]?.price)
											return (
												<div key={indexCity} className="inputContainer">
													<input type="text" placeholder={`Price per ${inboundInfo.unit}`} value={priceToShow === "" ? "" : priceToShow.formattedValue}
														onChange={(e) => {
															props.setVariableCost(prevState => {
																const citiesCopy = [...prevState.cities]
																citiesCopy[indexCity].inboundGroups.map((varInboundGroup, indexGroup) => {
																	if (inboundGroup.groupName === varInboundGroup.groupName) {
																		varInboundGroup.origins.map((origin, index) => {
																			if (origin.origin === inboundInfo.fullName) {
																				
																				origin.price = e.target.value === "" ? "" : formatCommas(e.target.value).numericValue;
																			}
																			return origin;
																		});
																	}
																	return inboundGroup
																})
																return {
																	...prevState,
																	cities: citiesCopy
																}
															})
														}}
													/>
												</div>
											)
										})}
									</div>
								))}
							</Col>
						</Row>
					</div>
				))}
				<div className="headerTitle">
					<h5>
						Outbound
					</h5>
					<div className="currencyButtonContainer">
						<button className={`currency ${props.variableCost?.currency?.logisticsOutbound === "USD" ? "selected" : ""}`} 
							onClick={() => {
								props.setVariableCost(prevState => {
                                            return {
                                                ...prevState,
                                                currency: {
                                                    ...prevState.currency,
                                                    logisticsOutbound: "USD"
                                                }
                                            }
                                        })
							}}
						>
							USD
						</button>
						
						<button className={`currency ${props.variableCost?.currency?.logisticsOutbound === "MXN" ? "selected" : ""}`} 
							onClick={() => {
								props.setVariableCost(prevState => {
                                            return {
                                                ...prevState,
                                                currency: {
                                                    ...prevState.currency,
                                                    logisticsOutbound: "MXN"
                                                }
                                            }
                                        })
							}}
						>
							MXN
						</button>

						<span>Cost per unit</span>
					</div>
				</div>
				<Row className="rowInformation">
					<Col lg={4} className="px-0" xs={{ span: 12 }}>
						<div className="informationHeader">
							<h5 className="port">Border city</h5>
							<h5 className="origin">Destination</h5>
						</div>

						{props.draft && props.showLogisticCosts && props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.map((destiny, indexDestiny) => (
							<div key={indexDestiny} className="informationValue">
								<div className="inputContainer firstElement">
									<input type="text" value={destiny.border?.fullName} placeholder="Add border city (optional)"
										onChange={(e) => {
											if (e.target.value !== "") {
												setShowPlacesContainer(`borderCity_${indexDestiny}`)
											} else {
												setShowPlacesContainer("")
											}

											const destinies = props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations

											destinies[indexDestiny].border = {
												fullName: e.target.value,
												city: "",
												state: "",
												country: "",
												latitude: undefined,
												longitude: undefined,
											}

											props.setDraft(prevState => {
												return {
													...prevState,
													siteSelectionReport: {
														...prevState.siteSelectionReport,
														assumption: {
															...prevState.siteSelectionReport.assumption,
															logistics: {
																...prevState.siteSelectionReport.assumption.logistics,
																logisticsOutbound: {
																	...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound,
																	destinations: destinies
																}
															}
														}
													}
												}

											})

											setCitySearch(e.target.value)
										}}
									/>
									<div className={`placesFoundContainer ${showPlacesContainer === `borderCity_${indexDestiny}` ? "show" : ""}`}>
										{placesFound.map((place, index) => {
											return (
												<span key={`place_${index}`} className="placeFound"
													onClick={() => {
														const state = place.context?.find(x => x.id.includes("region"))?.text;
														const country = place.context?.find(x => x.id.includes("country"))?.text;

														const destinies = props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations

														destinies[indexDestiny].border = {
															fullName: place.place_name,
															city: place.text,
															state: state ?? "",
															country: country ?? "",
															latitude: place.geometry.coordinates[1],
															longitude: place.geometry.coordinates[0],
														}

														props.setDraft(prevState => {
															return {
																...prevState,
																siteSelectionReport: {
																	...prevState.siteSelectionReport,
																	assumption: {
																		...prevState.siteSelectionReport.assumption,
																		logistics: {
																			...prevState.siteSelectionReport.assumption.logistics,
																			logisticsOutbound: {
																				...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound,
																				destinations: destinies
																			}
																		}
																	}
																}
															}

														})

														setShowPlacesContainer("None");
													}}>
													{place.place_name}
												</span>
											)
										})}
									</div>
								</div>

								<input className="secondElement" type="text" value={destiny.city} disabled />
							</div>
						))}
					</Col>

					<Col lg={8} className="citiesScroll" xs={{ span: 12 }}>
						<div className="informationHeaderCities">
							{props.variableCost && props.variableCost.cities.filter(city => props.draft && props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => (
								<h5 key={index} className="cityName">{city.cityName}</h5>
							))}
						</div>
						{props.draft && props.showLogisticCosts && props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.map((destiny, indexDestiny) => (
							destiny.fullName !== "" && <div key={indexDestiny} className="informationValueCities" name={destiny.fullName}>
								{props.variableCost && props.variableCost.cities.filter(city => props.draft && props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, indexCity) => {
									let priceToShow = city.outbound.filter(outbound => outbound.destination === destiny.fullName)[0]?.price === "" ?
									"" : formatCommas(city.outbound.filter(outbound => outbound.destination === destiny.fullName)[0]?.price)
									return (
									<div key={indexCity} className="inputContainer">
										<input type="text" placeholder={`Price per ${destiny.unit}`} value={priceToShow === "" ? "" : priceToShow.formattedValue}
											onChange={(e) => {
												props.setVariableCost(prevState => {
													const citiesCopy = [...prevState.cities]
													citiesCopy[indexCity].outbound = citiesCopy[indexCity].outbound.map((outbound, index) => {
														if (outbound.destination === destiny.fullName) {
															return {
																...outbound,
																price: e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
															}
														} else {
															return outbound
														}
													});
													return {
														...prevState,
														cities: citiesCopy
													}
												})
											}}
										/>
									</div>
									)
								}

								)}
							</div>
						))}
					</Col>
				</Row>
				<Row className="d-lg-none">
					<Col>
						<div className="mobile-actions">
							<button id="download" onClick={() => getSpreadsheet()}><span>Download(.xlsx)</span></button>
							<button onClick={() => logisticsCostsFile.current && logisticsCostsFile.current.click()}><span>Upload(.xlsx)</span></button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}